import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Party } from 'src/app/models/Party';

@Component({
  selector: 'app-chat-group',
  templateUrl: './app-chat-group.component.html',
  styleUrls: ['./app-chat-group.component.scss'],
})
export class AppChatGroupComponent {

  @Input() chat_id: number;
  @Input() party: Party;
  @Input() icon: string;
  @Input() redirection: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() lastMessage: string;
  @Input() newMessages: number;
  @Input() slidingOptionsSide: string;
  @Input() slidingOptionsColor: string;
  @Input() slidingOptionsIcon: string;

  @Output() onDelete = new EventEmitter<number>();


  constructor() { }


  public deleteChat(){
    this.onDelete.emit(this.chat_id);
  }

}
