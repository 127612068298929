import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Events, ModalController, NavController } from '@ionic/angular';
import { Notification } from 'src/app/models/Notification';
import { User } from 'src/app/models/User';
import { AddUserToJuergonModalPage } from 'src/app/pages/add-user-to-juergon-modal/add-user-to-juergon-modal.page';
import { AddUserToPartyModalPage } from 'src/app/pages/add-user-to-party-modal/add-user-to-party-modal.page';
import { LeaderPublicProfilePage } from 'src/app/pages/leader-public-profile/leader-public-profile.page';
import { SearchUsersPage } from 'src/app/pages/search-users/search-users.page';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { codeErrors } from 'src/app/utils/utils';

@Component({
  selector: 'app-user-item',
  templateUrl: './app-user-item.component.html',
  styleUrls: ['./app-user-item.component.scss'],
})
export class AppUserItemComponent implements OnInit {

  @Input() user: User;
  @Input() userDefault?: boolean = false;
  @Input() addVIP?: boolean = false;
  @Input() addFriends?: boolean = false;
  @Input() clickableUser: boolean;
  @Input() authId?: number;
  @Input() delete?:boolean = false;

  @Output() onAdd = new EventEmitter<number>();

  @Output() onDelete = new EventEmitter<number>();
  @Output() onAddPandilla = new EventEmitter<number>();
  
  constructor(private modalCtrl: ModalController,
    private navController: NavController,
    private apiService: ApiService, 
    private utilities: UtilitiesService,
    private events: Events) { }

  ngOnInit() {

  }

  async openModal(){
    
    if(!this.addVIP){

      if(this.addFriends){
        const modal = await this.modalCtrl.create({
          component: SearchUsersPage,
          cssClass: "",
          componentProps: {
          }
        });
    
        modal.onDidDismiss().then(dataReturned => {
          console.log("data returned en app user item (usuario seleccionado para añadir a grupo)", dataReturned);
          
          if(dataReturned.data == false){
            this.utilities.showToast('No has seleccionado ningún usuario');
          }else{
            this.addUser(dataReturned.data);
  
            console.log("emit value on add", dataReturned.data.id);
            
            let id: number = dataReturned.data.id;
            this.onAdd.emit(id);
  
          }
          
        });
    
        await modal.present();
      }else{
      

        const modal = await this.modalCtrl.create({
          component: AddUserToPartyModalPage,
          cssClass: "transparent-modal",
          componentProps: {
          }
        });
    
        modal.onDidDismiss().then(dataReturned => {
  
          
          console.log("data returned en app user item (usuario seleccionado para añadir a grupo)", dataReturned);
  
  
          if(dataReturned.data == false){
            this.utilities.showToast('No has seleccionado ningún usuario');
          }else{
            this.addUser(dataReturned.data);
  
            console.log("emit value on add", dataReturned.data.id);
            
            let id: number = dataReturned.data.id;
            this.onAdd.emit(id);
  
          }
          
        });
    
        await modal.present();
      }

    }else{
      const modal = await this.modalCtrl.create({
        component: AddUserToJuergonModalPage,
        cssClass: "transparent-modal",
        componentProps: {
        }
      });
  
      modal.onDidDismiss().then(dataReturned => {

        console.log("data returned en app user item (usuario seleccionado para añadir a juergon)", dataReturned);
  
  
          if(dataReturned.data == undefined || dataReturned.data == false){
            this.utilities.showToast('No has seleccionado ningún usuario');
          }else if(dataReturned.data == 1){
            console.log("emit add pandilla", dataReturned.data);
            
            this.onAddPandilla.emit(dataReturned.data);
          }else{
            console.log("evento add user to juergon");
            
            this.addUserToJuergon(dataReturned.data.id);
  
          }
          

      });
  
      await modal.present();
    }
    
  }

  public goToProfile(){
    
    /*if(this.clickable){
      this.modalCtrl.dismiss(this.user);
    }*/
    if(!this.userDefault){

      if(this.clickableUser){
        this.modalCtrl.dismiss(this.user);
      }else{
        this.goProfileModal();
      }
    }
  }

  public goProfile(){
    console.log("go profile");
    
    this.navController.navigateForward('/leader-public-profile/' + this.user.id);

  }

  public async goProfileModal(){
    console.log("go profile modal");
    
    const modal = await this.modalCtrl.create({
      component: LeaderPublicProfilePage,
      cssClass: "",
      componentProps: {
        id: this.user.id
      }
    });

    modal.onDidDismiss().then(dataReturned => {
      
      
    });

    await modal.present();
    
    //this.navController.navigateForward('/leader-public-profile/' + this.user.id);

  }

  public addUser(user: User): void {

    /*console.log("add user to bbdd");

    this.utilities.showLoading('Añadiendo a la pandilla...');
    this.apiService.getEntity('pandilla', user.id).subscribe((user: User)=>{

      this.apiService.userChanges.next(user);

      this.utilities.dismissLoading();

      this.utilities.showToast('Usuario añadido a tu pandilla correctamente');

    },(error)=>{
      
      this.utilities.dismissLoading();
      this.utilities.showToast(codeErrors(error));
    });*/
  }

  public addUserToJuergon(id){
    console.log();
    
    //this.events.publish('user:juergon', user);
    this.onAdd.emit(id)

  }

  public deleteUser(){
    console.log("emit del evento");
    
    this.onDelete.emit(this.user.id)
  }

}
