import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule, Storage } from '@ionic/storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Toast } from '@ionic-native/toast/ngx';
import { Push } from '@ionic-native/push/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddUserToPartyModalPageModule } from './pages/add-user-to-party-modal/add-user-to-party-modal.module';

import { AddUserToJuergonModalPageModule } from './pages/add-user-to-juergon-modal/add-user-to-juergon-modal.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { ModalSelectImagenesPageModule } from './pages/modal-select-images/modal-select-imagenes.module';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { SearchUsersPageModule } from './pages/search-users/search-users.module';
import { AddMyFriendsPageModule } from './pages/add-my-friends/add-my-friends.module';
import { LegalNoticesPageModule } from './pages/legal-notices/legal-notices.module';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { LeaderPublicProfilePageModule } from './pages/leader-public-profile/leader-public-profile.module';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import {MultipleDocumentsPicker} from '@ionic-native/multiple-document-picker/ngx';
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { AdMobFree } from '@ionic-native/admob-free/ngx';
import { AdmobService } from './services/admob.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicModule.forRoot({
      mode: 'ios'
     }),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
      
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Ng2SearchPipeModule,
    AddUserToPartyModalPageModule,
    AddUserToJuergonModalPageModule,
    IonicSelectableModule,
    ModalSelectImagenesPageModule,
    SearchUsersPageModule,
    AddMyFriendsPageModule,
    LegalNoticesPageModule,
    LeaderPublicProfilePageModule,
    

  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Toast,
    Push,
    Camera,
    Stripe,
    Facebook,
    GooglePlus,
    MediaCapture,
    Base64,
    SignInWithApple,
    GoogleMaps,
    Geolocation,
    NativeGeocoder,
    LaunchReview,
    MultipleDocumentsPicker,
    File,
    FilePath,
    AdMobFree,
    AdmobService,

    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
