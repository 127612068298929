/**
 * Variables globales de la aplicación en local
 */


export const environment = {
  production: false,

  // URL del dominio asociado a la aplicación, para comprobar si está o no suspendido
  domainUrl: 'https://development.xerintel.net/',
  //https://davidrodr.xerintel.net/
  // URL del endpoint de la api de pruebas
  apiUrl: 'https://davidrodr.xerintel.net/devxerintel/api/auth/',
  //apiUrl: 'http://192.168.0.15:8000/api/auth/', 

  /* apiUrl: 'http://localhost/laravel-base-api/public/api/auth/', */
    
  //Sender id para las notificaciones push
  senderID:'907496173177', 
  
  //Clave de stripe
  stripePublishableKey:'pk_test_***************'
};
