import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Notification } from 'src/app/models/Notification';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-users-item',
  templateUrl: './app-users-item.component.html',
  styleUrls: ['./app-users-item.component.scss'],
})
export class AppUsersItemComponent implements OnInit {

  @Input() users: User[];
  @Input() addUser?:boolean = false;
  @Input() defaultMargin?:boolean = false;
  @Input() addVIP?: boolean = false;
  @Input() addFriends?: boolean = false;
  @Input() clickable?: boolean = false;
  @Input() authId?: number;
  @Input() delete?:boolean = false;


  public userDefault: User;
  @Output() onDeleteUser = new EventEmitter<number>();
  @Output() onAddUser = new EventEmitter<number>();
  @Output() onAddUsersPandilla = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { 
    
  }


  public onDelete(event){
    this.onDeleteUser.emit(event)
  }

  public onAdd(event){
    console.log("add user in users", event);
    
    this.onAddUser.emit(Number(event));
  }

  public onAddPandilla(event){
    console.log("emit add pandilla from users", event);
    
    this.onAddUsersPandilla.emit(event);
  }




}
