import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { SearchUsersPage } from '../search-users/search-users.page';

@Component({
  selector: 'app-add-user-to-party-modal',
  templateUrl: './add-user-to-party-modal.page.html',
  styleUrls: ['./add-user-to-party-modal.page.scss'],
})
export class AddUserToPartyModalPage implements OnInit {

  public defaultValues = { lower: 0, upper: 100 };

  constructor(private apiService:ApiService,
    private menuCtrl:MenuController,
    private modalCtrl: ModalController,
    private navController: NavController) { }

  public ngOnInit():void{
    this.menuCtrl.enable(true)
  }

  public closeModal(){
    this.modalCtrl.dismiss(false);
  }

  public pandilla(){
    //this.navController.navigateForward('/mi-pandilla');
    this.closeModal();
  }


  public async buscarUsuarios(){
    
    const modal = await this.modalCtrl.create({
      component: SearchUsersPage,
      cssClass: "",
      componentProps: {
      }
    });

    modal.onDidDismiss().then(dataReturned => {
      console.log("usuario rtornado del mdal",dataReturned);
      
      if(dataReturned.data == false){
        setTimeout(()=>{
          this.modalCtrl.dismiss(false);
        }, 200)
      }else{
        setTimeout(()=>{
          this.modalCtrl.dismiss(dataReturned.data);
        }, 200)
      }
      
    });

    await modal.present();
    //this.navController.navigateForward('/search-users');
    //this.closeModal();
  }

  public async friends(){
    
    const modal = await this.modalCtrl.create({
      component: SearchUsersPage,
      cssClass: "",
      componentProps: {
        friends: true 
      }
    });

    modal.onDidDismiss().then(dataReturned => {
      console.log("usuario rtornado del mdal",dataReturned);
      
      if(dataReturned.data == false){
        setTimeout(()=>{
          this.modalCtrl.dismiss(false);
        }, 200)
      }else{
        setTimeout(()=>{
          this.modalCtrl.dismiss(dataReturned.data);
        }, 200)
      }
      
    });

    await modal.present();
    //this.navController.navigateForward('/search-users');
    //this.closeModal();
  }

  public vips(){
    this.navController.navigateForward('/add-vip-user');
    this.closeModal();
  }

  closeModalBackdrop() {
    this.modalCtrl.dismiss(null, 'backdrop');
  }

}
