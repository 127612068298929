import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MenuController } from '@ionic/angular';
import { User } from 'src/app/models/User';
import { NotificacionesNuevasService } from 'src/app/services/notificaciones-nuevas.service';

@Component({
  selector: 'app-add-my-friends',
  templateUrl: './add-my-friends.page.html',
  styleUrls: ['./add-my-friends.page.scss'],
})
export class AddMyFriendsPage implements OnInit {

  filterTerm: string;

  public users: User[] = [
    {
      email : "hola",
      avatar: "../../../assets/imgs/user1.png",
      nickname: '@maria1231',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user2.png",
      nickname: '@lucia12',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user3.png",
      nickname: '@laura992',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user4.png",
      nickname: '@jesus2452',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user5.png",
      nickname: '@lauraalv12',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user6.png",
      nickname: '@antonio21',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user7.png",
      nickname: '@raul1',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user8.png",
      nickname: '@davidrodr',
    },
    {
      email : "hola",
      avatar: "../../../assets/imgs/user9.png",
      nickname: '@raquel02',
    },
    
  ]

  constructor(private apiService:ApiService,
    private menuCtrl:MenuController,
    private notificacionesNuevasService: NotificacionesNuevasService
    ) { }

  public ngOnInit():void{
    this.menuCtrl.enable(true)
  }

}
