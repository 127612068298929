import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-select-imagenes',
  templateUrl: './modal-select-imagenes.page.html',
  styleUrls: ['./modal-select-imagenes.page.scss'],
})
export class ModalSelectImagenesPage implements OnInit {
  constructor(
    private modalController: ModalController
  ) { }
  ngOnInit() {}
  closeModal() {
    this.modalController.dismiss(null, 'backdrop');
  }
  startCapture(type) {
    this.modalController.dismiss(type, 'select');
  }
}