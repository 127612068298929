import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MenuController, ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/app/models/User';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { codeErrors } from 'src/app/utils/utils';
import { NotificacionesNuevasService } from 'src/app/services/notificaciones-nuevas.service';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.page.html',
  styleUrls: ['./search-users.page.scss'],
})
export class SearchUsersPage implements OnInit {

  filterTerm: string;

  public isLoading: boolean = true;
  public users: User[] = [];
  public friends: boolean = false;
  constructor(private apiService: ApiService,
    private menuCtrl: MenuController,
    private utilities: UtilitiesService,
    private modalController: ModalController,
    private navParams: NavParams,
    private notificacionesNuevasService: NotificacionesNuevasService

  ) {
    this.friends = this.navParams.data.friends;
  }

  public ngOnInit(): void {
    this.menuCtrl.enable(true);

    this.getUsers();
  }

  public getUsers() {
    if (!this.friends) {
      this.isLoading = true;
      this.apiService.getEntity('users').subscribe((users: User[]) => {
        this.users = users;
        this.isLoading = false;

      }, (error) => {
        this.isLoading = false;
        this.utilities.showToast(codeErrors(error));
      });
    } else {
      this.isLoading = true;
      this.apiService.getEntity('user').subscribe((user: User) => {
        this.users = user.friends;
        this.isLoading = false;

      }, (error) => {
        this.isLoading = false;
        this.utilities.showToast(codeErrors(error));
      });
    }

  }

  public closeModal() {
    this.modalController.dismiss(false);
  }

}
