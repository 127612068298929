import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  AdMobFree,
  AdMobFreeBannerConfig,
  AdMobFreeInterstitialConfig,
  AdMobFreeRewardVideoConfig
} from '@ionic-native/admob-free/ngx';

@Injectable({
  providedIn: 'root'
})
export class AdmobService {

  //Interstitial Ad's Configurations
  interstitialConfig: AdMobFreeInterstitialConfig = {
    // add your config here
    // for the sake of this example we will just use the test config
     isTesting: true, 
    autoShow: false,
    id: "ca-app-pub-8232953884808077/8991315387"
  };

  //Reward Video Ad's Configurations
  RewardVideoConfig: AdMobFreeRewardVideoConfig = {
    isTesting: true, // Remove in production 
    autoShow: false,
    id: "ca-app-pub-8232953884808077/1112825360"
  };

  constructor(
    private platform: Platform,
    private admobFree: AdMobFree
  ) {
    platform.ready().then(() => {

      // Load ad configuration
      this.admobFree.interstitial.config(this.interstitialConfig);
      //Prepare Ad to Show
      this.admobFree.interstitial.prepare()
        .then(() => {
          // alert(1);
        }).catch(e => console.log(e));


      // Load ad configuration
      this.admobFree.rewardVideo.config(this.RewardVideoConfig);
      //Prepare Ad to Show
      this.admobFree.rewardVideo.prepare()
        .then(() => {
          // alert(2);
        }).catch(e => console.log(e));

    });

    //Handle interstitial's close event to Prepare Ad again
    this.admobFree.on('admob.interstitial.events.CLOSE').subscribe(() => {
      this.admobFree.interstitial.prepare()
        .then(() => {
          console.log("Interstitial CLOSE");
        }).catch(e => console.log(e));
    });
    //Handle Reward's close event to Prepare Ad again
    this.admobFree.on('admob.rewardvideo.events.CLOSE').subscribe(() => {
      this.admobFree.rewardVideo.prepare()
        .then(() => {
          console.log("Reward Video CLOSE");
        }).catch(e => console.log(e));
    });
  }


  BannerAd() {

    let bannerConfig: AdMobFreeBannerConfig = {
      isTesting: true, // Remove in production
      autoShow: false,
      size: 'LARGE_BANNER',
      //overlap: true,
      bannerAtTop: true,
      id: "ca-app-pub-8232953884808077/4635732438"
    };
    this.admobFree.banner.config(bannerConfig);

    
    this.admobFree.banner.prepare().then((response) => {
      // success
      console.log("admob prepare success", response);
      this.admobFree.banner.show().then((response2)=>{
        console.log("reponse show", response2);
        
      }, (error)=>{
        console.log("error show", error);
        
      });

      
    }).catch(e => {
      console.log(e);
      
    });

    /*this.admobFree.banner.prepare().then(() => {
      // success
      console.log("admob prepare success");
      
    }, (error)=>{
      console.log("error admob", error);
      
    });*/

    
  }

  closebanner() {
    this.admobFree.banner.hide().then(() => {
      // success
    }).catch(e => console.log(e));
  }

  InterstitialAd() {
    //Check if Ad is loaded
    this.admobFree.interstitial.isReady().then(() => {
      //Will show prepared Ad
      this.admobFree.interstitial.show().then(() => {
      })
        .catch(e => console.log('show', e));
    })
      .catch(e => console.log('isReady', e));
  }

  RewardVideoAd() {
    //Check if Ad is loaded
    this.admobFree.rewardVideo.isReady().then(() => {
      //Will show prepared Ad
      this.admobFree.rewardVideo.show().then(() => {
      })
        .catch(e => console.log('show', e));
    })
      .catch(e => console.log('isReady', e));
  }


}