import { not } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Notification } from 'src/app/models/Notification';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-notification-item',
  templateUrl: './app-notification-item.component.html',
  styleUrls: ['./app-notification-item.component.scss'],
})
export class AppNotificationItemComponent implements OnInit {

  @Input() notification: Notification;
  @Output() onDelete = new EventEmitter<number>();


  @Input() slidingOptionsSide: string;
  @Input() slidingOptionsColor: string;
  @Input() slidingOptionsIcon: string;
  constructor(private navController: NavController) { 
    
  }

  ngOnInit() { }

  public deleteNotification(id: number): void {
    this.onDelete.emit(id);
  }

  public getHour(date){
    return moment(date).format('HH:mm');
  }

  public getDayShort(date){
    return moment(date).format('ddd');
  }

  public getDay(date){
    return moment(date).format('DD');
  }

  public goToRequest(){
    if(this.notification.request_group_party_id){
      this.navController.navigateForward('/juergon-request-join/' + this.notification.request_group_party_id);
    }else if(this.notification.request_user_party_id){
      this.navController.navigateForward('/juergon-request-invitation/' + this.notification.request_user_party_id);

    }
  }

}
