import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { SearchUsersPage } from '../search-users/search-users.page';
import { User } from 'src/app/models/User';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { codeErrors } from 'src/app/utils/utils';

@Component({
  selector: 'app-add-user-to-juergon-modal',
  templateUrl: './add-user-to-juergon-modal.page.html',
  styleUrls: ['./add-user-to-juergon-modal.page.scss'],
})
export class AddUserToJuergonModalPage implements OnInit {


  public user: User;
  public isLoading = true;

  constructor(private apiService:ApiService,
    private menuCtrl:MenuController,
    private modalCtrl: ModalController,
    private navController: NavController,
    private utilities: UtilitiesService) { }

  public ngOnInit():void{
    this.menuCtrl.enable(true);

  }

  

  public closeModal(){
    this.modalCtrl.dismiss(false);
  }

  public pandilla(){
    //this.navController.navigateForward('/mi-pandilla');
    this.modalCtrl.dismiss(1);
  }


  public async friends(){
    
    const modal = await this.modalCtrl.create({
      component: SearchUsersPage,
      cssClass: "",
      componentProps: {
        friends: true 
      }
    });

    modal.onDidDismiss().then(dataReturned => {
      console.log("usuario rtornado del mdal",dataReturned);
      
      if(dataReturned.data == false){
        setTimeout(()=>{
          this.modalCtrl.dismiss(false);
        }, 200)
      }else{
        setTimeout(()=>{
          this.modalCtrl.dismiss(dataReturned.data);
        }, 200)
      }
      
    });

    await modal.present();
    //this.navController.navigateForward('/search-users');
    //this.closeModal();
  }

  public vips(){
    this.navController.navigateForward('/add-vip-user');
    this.closeModal();
  }


  closeModalBackdrop() {
    this.modalCtrl.dismiss(null, 'backdrop');
  }
}
