import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-legal-notices',
  templateUrl: './legal-notices.page.html',
  styleUrls: ['./legal-notices.page.scss'],
})
export class LegalNoticesPage implements OnInit {

  form: FormGroup;

  constructor(private modalController: ModalController,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      security: [false, Validators.requiredTrue],
      personal_data: [false, Validators.requiredTrue],
    });
  }

  public closeModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  public closeModalBack() {
    this.modalController.dismiss({
      'dismissed': false
    });
  }


  public submitForm(): void {
    this.closeModal();
  }


}
