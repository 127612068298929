import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'cover-page', pathMatch: 'full' },
  { path: 'cover-page', loadChildren: () => import('./pages/cover-page/cover-page.module').then(m => m.CoverPagePageModule) },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule) },
  { path: 'verification-code/:id', loadChildren: () => import('./pages/verification-code/verification-code.module').then(m => m.VerificationCodePageModule) },
  { path: 'details', canActivate: [AuthGuard], loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsPageModule) },
  { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'add-party', canActivate: [AuthGuard], loadChildren: () => import('./pages/add-party/add-party.module').then(m => m.AddPartyPageModule) },
  { path: 'details-party/:party_id', canActivate: [AuthGuard], loadChildren: () => import('./pages/details-party/details-party.module').then(m => m.DetailsPartyPageModule) },
  { path: 'edit-party/:party_id', canActivate: [AuthGuard], loadChildren: () => import('./pages/edit-party/edit-party.module').then(m => m.EditPartyPageModule) },
  { path: 'add-user-to-party-modal', canActivate: [AuthGuard], loadChildren: () => import('./pages/add-user-to-party-modal/add-user-to-party-modal.module').then(m => m.AddUserToPartyModalPageModule) },
  { path: 'search', canActivate: [AuthGuard], loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule) },
  { path: 'search-users', canActivate: [AuthGuard], loadChildren: () => import('./pages/search-users/search-users.module').then(m => m.SearchUsersPageModule) },
  { path: 'add-my-friends', canActivate: [AuthGuard], loadChildren: () => import('./pages/add-my-friends/add-my-friends.module').then(m => m.AddMyFriendsPageModule) },
  { path: 'mi-pandilla', canActivate: [AuthGuard], loadChildren: () => import('./pages/mi-pandilla/mi-pandilla.module').then(m => m.MiPandillaPageModule) },
  { path: 'my-friends', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-friends/my-friends.module').then(m => m.MyFriendsPageModule) },
  { path: 'profile-view', canActivate: [AuthGuard], loadChildren: () => import('./pages/profile-view/profile-view.module').then(m => m.ProfileViewPageModule) },
  { path: 'public-profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/public-profile/public-profile.module').then(m => m.PublicProfilePageModule) },
  { path: 'edit-public-profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/edit-public-profile/edit-public-profile.module').then(m => m.EditPublicProfilePageModule) },
  { path: 'forgot-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'chats', canActivate: [AuthGuard], loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule) },
  { path: 'interior-chat/:id_chat/:nombre_chat/:ultimo_mensaje', canActivate: [AuthGuard], loadChildren: () => import('./pages/interior-chat/interior-chat.module').then(m => m.InteriorChatPageModule) },
  { path: 'tabs', canActivate: [AuthGuard], loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'main-user-scren', loadChildren: './pages/main-user-scren/main-user-scren.module#MainUserScrenPageModule' },
  { path: 'user-request', loadChildren: './pages/user-request/user-request.module#UserRequestPageModule' },
  { path: 'user-request/:party_id', loadChildren: './pages/user-request/user-request.module#UserRequestPageModule' },
  { path: 'leader-public-profile/:user_id', loadChildren: './pages/leader-public-profile/leader-public-profile.module#LeaderPublicProfilePageModule' },
  { path: 'add-vip-user', loadChildren: './pages/add-vip-user/add-vip-user.module#AddVipUserPageModule' },
  { path: 'juergon-request-join/:id', loadChildren: './pages/juergon-request-join/juergon-request-join.module#JuergonRequestJoinPageModule' },
  { path: 'juergon-request-invitation/:id', loadChildren: './pages/juergon-request-invitation/juergon-request-invitation.module#JuergonRequestInvitationPageModule' },
  { path: 'add-user-to-juergon-modal', loadChildren: './pages/add-user-to-juergon-modal/add-user-to-juergon-modal.module#AddUserToJuergonModalPageModule' },
  { path: 'map', canActivate: [AuthGuard], loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule) },







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
